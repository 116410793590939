import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Tooltip from '@atoms/Tooltip';

const StyledContainer = styled.div`
  ${({ theme, type }) => css`
    padding: 0 0 32px 0;
    ${type === 'checkbox' &&
    `
    display: flex;
    flex-direction: row;
    padding-bottom: ${theme.spacing(1)};

    input{
      order:1;
      margin:0 ${theme.spacing(2)} 0 0;
    }

    label{
      padding:0;
      order:2;
    }
    `}
  `}
`;

const StyledLabel = styled.label`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    ${theme.typography.titleS};
    color: ${theme.colors.white};
    padding-bottom: ${theme.spacing(1)};
    position: relative;
    min-height: 34px;

    &[for] {
      cursor: pointer;
    }

    span {
      font-style: normal;
      text-transform: none;
      ${theme.typography.footnote};
      display: inline-block;
      padding-left: ${theme.spacing(0.5)};
    }
  `}
`;

const Input = styled.input`
  ${({ theme }) => css`
    ${theme.typography.footnote};
    position: relative;
    left: 0;
    z-index: 1;
    appearance: none;
    background: transparent;
    color: ${theme.colors.white};
    border: 1px solid rgba(102, 102, 102, 0.4);
    border-radius: ${theme.spacing(2)};
    height: ${theme.spacing(5)};
    width: ${theme.spacing(7)};
    text-align: center;

    ::placeholder {
      color: rgba(${theme.colors.white}, 0.5);
    }

    :focus {
      outline: none;
      border: 1px solid ${theme.colors.white};
      border-radius: ${theme.spacing(2)};
    }

    &[type='number'],
    &[type='text'],
    &[type='tel']. &[type='email'] {
      -moz-appearance: textfield; /*For FireFox*/

      &::-webkit-inner-spin-button {
        /*For Webkits like Chrome and Safari*/
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &[type='checkbox'] {
      height: ${theme.spacing(2.5)};
      width: ${theme.spacing(2.5)};
      border-color: ${theme.colors.white};
      border-radius: ${theme.spacing(0.7)};
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s;

      :before {
        content: '';
        display: block;
        width: 11px;
        height: 6px;
        border-left: 2px solid ${theme.colors.black};
        border-bottom: 2px solid ${theme.colors.black};
        opacity: 0;
        transform: rotate(-45deg);
        margin-top: -3px;
        transition: all 0.2s;
      }

      :checked {
        background-color: ${theme.colors.white};

        :before {
          opacity: 1;
        }
      }
    }
  `}
`;

const InputForm = React.memo(
  React.forwardRef(
    ({ tooltip, tooltipLeft, unitsLabel, label, ...rest }, ref) => (
      <StyledContainer type={rest.type}>
        <StyledLabel for={rest.id ?? false}>
          {label}
          {tooltip && <Tooltip tooltip={tooltip} left={tooltipLeft} />}
        </StyledLabel>
        <Input ref={ref} {...rest} />
        <span> {unitsLabel}</span>
      </StyledContainer>
    )
  )
);

InputForm.propTypes = {
  unitsLabel: PropTypes.string,
  label: PropTypes.string,
  tooltipLeft: PropTypes.bool,
};

export default InputForm;
