import { adapter as cardAdapter } from '@molecules/Card';

export default ({
  sendUserData,
  nameLabel,
  nameTooltip,
  emailLabel,
  emailTooltip,
  phoneLabel,
  phoneTooltip,
  ageLabel,
  ageUnitLabel,
  ageTooltip,
  bodyweightLabel,
  bodyweightUnitLabel,
  calculateButtonLabel,
  durationOfWorkoutDropdown,
  durationOfWorkoutLabel,
  genderLabel,
  genderList,
  genderTooltip,
  nextButtonLabel,
  numberOfWorkoutsDropdown,
  numberOfWorkoutsLabel,
  recalculateButtonLabel,
  subtitle,
  title,
  titleTooltip,
  secondaryTitle,
  trainingObjectivesDropdown,
  trainingObjectivesLabel,
  trainingObjectivesTooltip,
  typeOfDietDropdown,
  typeOfDietLabel,
  typeOfDietTooltip,
  typeOfWorkoutDropdown,
  typeOfWorkoutLabel,
  typeOfWorkoutTooltip,
  resultsTitleDailyAmount,
  occasionSplitTitle,
  occasionSplitTooltip,
  occasionSplitTooltip2,
  productPushTitle,
  recipesTitle,
  explanationOfResults,
  explanationTooltip,
  productPushCta,
  resultsTexts,
}) => ({
  sendUserData,
  nameLabel,
  nameTooltip,
  emailLabel,
  emailTooltip,
  phoneLabel,
  phoneTooltip,
  ageLabel,
  ageUnitLabel,
  ageTooltip,
  bodyweightLabel,
  bodyweightUnitLabel,
  calculateButtonLabel,
  durationOfWorkoutDropdown,
  durationOfWorkoutLabel,
  genderLabel,
  genderList,
  genderTooltip,
  nextButtonLabel,
  numberOfWorkoutsDropdown,
  numberOfWorkoutsLabel,
  recalculateButtonLabel,
  subtitle,
  title,
  titleTooltip: titleTooltip.titleTooltip,
  secondaryTitle,
  trainingObjectivesDropdown,
  trainingObjectivesLabel,
  trainingObjectivesTooltip,
  typeOfDietDropdown,
  typeOfDietLabel,
  typeOfDietTooltip,
  typeOfWorkoutDropdown,
  typeOfWorkoutLabel,
  typeOfWorkoutTooltip,
  resultsTitleDailyAmount,
  occasionSplitTitle,
  occasionSplitTooltip: occasionSplitTooltip.occasionSplitTooltip,
  occasionSplitTooltip2: occasionSplitTooltip2.occasionSplitTooltip2,
  productPushTitle,
  recipesTitle,
  explanationOfResults: explanationOfResults.explanationOfResults,
  explanationTooltip: explanationTooltip.explanationTooltip,
  productPushCta,
  resultsTexts: resultsTexts.map(
    ({
      proteinMinValue,
      proteinMaxValue,
      proteinSplit,
      occasionSplit,
      productPush,
      productFlavours,
      calculatorResultsCards,
    }) => ({
      proteinMinValue,
      proteinMaxValue,
      proteinSplit,
      occasionSplit,
      occasionSplitTooltip,
      productPushTitle,
      productPush,
      productFlavours,
      calculatorResultsCards:
        calculatorResultsCards &&
        calculatorResultsCards.map((card) => cardAdapter(card)),
    })
  ),
});
